<template>
  <div class="login">
    <div class="empty" @click="onCloseCsWidget"></div>
    <div class="widget">
      <div class="row">
        <span class="message">
          {{ this.title ? this.title : "请扫描下方二维码哦～" }}
        </span>
      </div>
      <div class="row">
        <div class="img">
          <img :src="url" alt="" />
        </div>
      </div>
      <div class="row" v-if="isH5">
        <div class="btn">
          <button class="copy" @click="onCopyPassword">
            一键复制微信号
          </button>
          <textarea v-model="wechatId" id="copy-textarea" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const WECHAT_ID_KEY = "market-tutor-wechatId";
const WECHAT_URL_KEY = "market-tutor-wechatUrl";

import { Notify } from "vant";
import { isMobile, isWeiXin } from "@/util/browser.js";
export default {
  props: {
    title: String,
    vId: String,
    vUrl: String,
  },
  data() {
    return {
      wechatId: this.vId ? this.vId : "hepulaocai",
      url: this.vUrl ? this.vUrl : require("@/assets/images/zhujiao0.png"),
      weChats: [
        {
          url: require("@/assets/images/zhujiao0.png"),
          wechatId: "hepulaocai",
        },
        {
          url: require("@/assets/images/zhujiao1.png"),
          wechatId: "kaiweitalk1",
        },
      ],
      idx: 0,
      isH5: false,
    };
  },
  methods: {
    onCopyPassword() {
      this.$nextTick(() => {
        const text = document.getElementById("copy-textarea");
        text.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
          Notify({
            type: "success",
            message: "已复制微信号:" + this.wechatId,
          });
        }
      });
    },
    onCloseCsWidget() {
      this.$EventBus.$emit("onCloseCsWidget", true);
    },
  },
  mounted() {
    const isMobileFlag = isMobile();
    const isWeixinFlag = isWeiXin();
    console.log("isMobile", isMobileFlag, isWeixinFlag);
    this.isH5 = isMobileFlag && !isWeixinFlag;
  },
};
</script>

<style lang="less" scoped>
.login {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  .empty {
    flex: 1;
  }
  .widget {
    border-radius: 24px 24px 0px 0px;
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.5);
    clip-path: inset(-24px 0px 0px 0px);
    background: #ffffff;
    height: 480px;
    .row {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #000000;
      letter-spacing: 3.92px;
      font-weight: 500;
      .message {
        margin: 40px 0 60px 0;
      }
      img {
        width: 170px;
        height: 170px;
      }
      .cs-name {
        margin: 10px 0 0 0;
      }
      .btn {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .copy {
          background: #ff8423;
          border-radius: 12px;
          width: 280px;
          height: 46px;
          border: none;
          font-size: 20px;
          color: #ffffff;
          letter-spacing: 0.44px;
          font-weight: 500;
        }
        #copy-textarea {
          position: absolute;
          left: -99999px;
        }
      }
    }
  }
}
</style>
